import configData from "../config.json";
import { authHeader, handleResponse } from '../_helpers/index';

export const marketService = {
    getPublished,
    getUnpublished,
    getMarketDetails,
    createMarket,
    updateMarketAfterConditionCreation,
    updateMarketAfterMarketCreation,
    updateCreateConditionConfirmed,
    updateCreateMarketConfirmed,
    updateMarketAfterInitialFunding,
    updateInitialFundingConfirmed,
    updateMarketAfterResolution,
    updateResolveMarketConfirmed,
    updateMarket,
    updateMarketAfterUmaQuestionCreation,
    updateRealityQuestionConfirmed,
    updateOrder
};

function getPublished() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${configData.API_URL}/markets/get/published`, requestOptions).then(handleResponse);
}

function getUnpublished() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${configData.API_URL}/markets/get/unpublished`, requestOptions).then(handleResponse);
}

function getMarketDetails(id){
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${configData.API_URL}/market/detail/`+id, requestOptions).then(handleResponse);
}

function createMarket(
    {
        question, 
        ancillary_data, 
        reward_token, 
        reward, 
        proposal_bond, 
        liveness, 
        option_1, 
        option_2, 
        market_ends_on, 
        about_market, 
        // oracle, 
        resolution_source, 
        option_1_odd, 
        option_2_odd, 
        permalink, 
        category, 
        image_url,
        initial_funding_amount
    }
) {
    const oracle = configData.addresses.conditionResolverUsingReality;
    const arbitrator = configData.addresses.realityArbitrator;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify({ 
            question, 
            option_1, option_2, about_market, 
            oracle, 
            resolution_source, 
            option_1_odd, 
            option_2_odd, 
            permalink, 
            image_url, 
            initial_funding_amount, 
            ancillary_data,
            reward_token, 
            reward, 
            proposal_bond, 
            market_ends_on,
            category,
            liveness })
    };

    return fetch(`${configData.API_URL}/market/create`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateMarketAfterConditionCreation(id, receiptFromBlockChain, collectionIds, positionIds) {
    var blockChainReturnValues = receiptFromBlockChain.events.ConditionPreparation.returnValues;
    var dataToBeSent = {
        id, 
        condition_id: blockChainReturnValues.conditionId,
        collection_ids: collectionIds,
        position_ids: positionIds,
        blockchain_response: receiptFromBlockChain
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-after-condition-creation`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateMarketAfterMarketCreation(id, receiptFromBlockChain) {
    var blockChainReturnValues = receiptFromBlockChain.events.FixedProductMarketMakerCreation.returnValues;
    var dataToBeSent = {
        id, 
        fpmm_market_maker_address: blockChainReturnValues.fixedProductMarketMaker,
        blockchain_response: receiptFromBlockChain
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-after-market-linking`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateMarketAfterUmaQuestionCreation(id, receiptFromBlockChain, conditionId, collectionIds, positionIds) {
    var question_id = receiptFromBlockChain.events.QuestionInitialized.returnValues.questionID;
    var dataToBeSent = {
        id, 
        question_id : question_id,
        condition_id: conditionId,
        collection_ids: collectionIds,
        position_ids: positionIds,
        blockchain_response: receiptFromBlockChain
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-after-question-creation`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateMarketAfterInitialFunding(id, receiptFromBlockChain) {
    var blockChainReturnValues = receiptFromBlockChain.events.FPMMFundingAdded.returnValues;
    var dataToBeSent = {
        id, 
        blockchain_response: receiptFromBlockChain
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-after-initial-funding`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}
function updateMarketAfterResolution(id, receiptFromBlockChain, resolutionSubmitted) {
    var dataToBeSent = {
        id, 
        blockchain_response: receiptFromBlockChain,
        resolution_submitted: resolutionSubmitted
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-after-resolution`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateResolveMarketConfirmed(marketId, confirmed) {
    var dataToBeSent = {
        market_id: marketId,
        confirmed
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-resolve-market-confirmed`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateRealityQuestionConfirmed(marketId, confirmed) {
    var dataToBeSent = {
        market_id: marketId,
        confirmed
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market-reality-question-confirmed`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateCreateConditionConfirmed(marketId, confirmed) {
    var dataToBeSent = {
        market_id: marketId,
        confirmed
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-blockchain-condition-confirmed`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateCreateMarketConfirmed(marketId, confirmed) {
    var dataToBeSent = {
        market_id: marketId,
        confirmed
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-blockchain-market-confirmed`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateInitialFundingConfirmed(marketId, confirmed) {
    var dataToBeSent = {
        market_id: marketId,
        confirmed
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-initial-funding-confirmed`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateMarket(marketId, aboutMarket, news) {
    var dataToBeSent = {
        market_id: marketId,
        about_market: aboutMarket,
        news: news
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(dataToBeSent)
    };

    return fetch(`${configData.API_URL}/market/update-market`, requestOptions)
        .then(handleResponse)
        .then(market => {
            return market;
        });
}

function updateOrder(id, order) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ order })
    };
    return fetch(`${configData.API_URL}/markets/${id}/order`, requestOptions).then(handleResponse);
}

