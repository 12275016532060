import React, { useState, useEffect } from 'react';

import { Router, Route, Link } from 'react-router-dom';

import { history, getChainData } from './_helpers';
import { authenticationService } from './_services/index';
import { PrivateRoute, PublicRoute } from './_components/index';
import { HomePage } from './components/HomePage/index';
import { LoginPage } from './components/LoginPage/index';
import EditMarket from './components/Market/EditMarket';
import CreateMarket from './components/Market/CreateMarket';
import { PublishedMarkets } from './components/Market/PublishedMarkets';
import { UnpublishedMarkets } from './components/Market/UnpublishedMarkets';
import { Web3connect } from './components/Web3connect';
import Market from './components/Market/Market';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import Web3 from "web3";


const App = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [globalState, setGlobalState] = useState({
        accounts : [],
        currentAddress : "", 
        web3 : null,
        balance : "",
        collateralBalance: "",
        provider : null,
        connected : false,
        chainId : 1,
        installMetamask : false
    })

    useEffect(() => {
        authenticationService.currentUser.subscribe(x => {
            setCurrentUser(x);
        });
        
    })



    const logout = () => {
        authenticationService.logout();
        history.push('/login');
    }
    return (
        <Router history={history}>
            <div>
                {currentUser &&
                <>
                    <ToastContainer 
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        closeOnClick
                    />
                    <nav className="navbar navbar-expand navbar-dark bg-dark justify-content-center">
                        <div className="container">
                            <div className="navbar-nav">
                                {/* <Link to="/" className="nav-item nav-link">Home</Link> */}
                                <Link to="/create-market" className="nav-item nav-link">Create Market</Link>
                                <Link to="/published-markets" className="nav-item nav-link">Published Markets</Link>
                                <Link to="/unpublished-markets" className="nav-item nav-link">Unpublished Markets</Link>
                            </div>
                            <div className="navbar-nav">
                                <Web3connect globalState={globalState} setGlobalState={setGlobalState} />
                                <a onClick={logout} className="nav-item nav-link">Logout</a>
                            </div>
                        </div>
                    </nav>
                </>
                }
                <div className="jumbotron">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <PrivateRoute exact path="/" component={HomePage} />
                                <PrivateRoute exact path="/create-market" component={CreateMarket}  globalState={globalState} setGlobalState={setGlobalState}/>
                                <PrivateRoute exact path="/market/:id" component={Market}  globalState={globalState}/>
                                <PrivateRoute exact path="/published-markets" component={PublishedMarkets} />
                                <PrivateRoute exact path="/unpublished-markets" component={UnpublishedMarkets} globalState={globalState} setGlobalState={setGlobalState}/>
                                <PrivateRoute exact path="/market/edit/:id" component={EditMarket}/>
                                <PublicRoute restricted={true} path="/login" component={LoginPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
};

export default App;