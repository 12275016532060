import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Web3 from "web3";
import USDC from "../abis/USDC.json"
import { amountSToB, reFormatHexAddress} from '../_helpers/utilities';
import configData from "../config.json";


export const Web3connect = ({globalState, setGlobalState}) => {
    const alertFromWeb3 = () => {
        alert('alert from web3')
    }

    const logAfterConnect = () => {
    }

    const afterConnect = async (web3) => {
        const accounts = await web3.eth.getAccounts();

        if(accounts.length > 0){
            let firstAccount = accounts[0];
            
            registerProviderEvents();

            const chainId = await web3.eth.getChainId();

            const collateralToken = await new web3.eth.Contract(USDC, configData.addresses.collateralToken);
            var collateralBalance = await collateralToken.methods.balanceOf(firstAccount).call({from: firstAccount})
            collateralBalance = amountSToB(collateralBalance)

            //set the balance of current account
            const weiBalance = await web3.eth.getBalance(firstAccount)
            let ethBal = await web3.utils.fromWei(weiBalance);

            setGlobalState({
                ...globalState, 
                collateralBalance,
                currentAddress: firstAccount,
                web3: web3,
                balance: ethBal,
                chainId: chainId,
                accounts: accounts
            });

            //to be done
            //tbd
            //load assets or data needed for page based on account
        }
    }


    const registerProviderEvents = () => {
        window.ethereum.on('accountsChanged', (accounts) => {
            // Handle the new accounts, or lack thereof.
            // "accounts" will always be an array, but it can be empty.
            window.location.reload();
        });
        
        window.ethereum.on('chainChanged', (chainId) => {
            window.location.reload();
        });

        // window.ethereum.on('disconnect', (error) => {
        //     console.log('user deleted all the accounts')
        // });
    }

    const connect = async (e) => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const web3 = new Web3(window.ethereum);
            try {
                // Request account access if needed
                // await window.ethereum.enable();
                // const accounts = await window.ethereum.send('eth_requestAccounts');
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

                await afterConnect(web3)
                logAfterConnect()
            } catch (error) {
                console.log('User rejected');
            }
        }
        // return false;
    }
    const getConvertedBalance = () => {

    }


    useEffect(() => {
        if (typeof window.ethereum === 'undefined' || !window.ethereum.isMetaMask) {
            setGlobalState({...globalState, installMetamask: true})
        }else{
            console.log('use effect ethereum available')
            const web3 = new Web3(window.ethereum);
            afterConnect(web3).then(() => {logAfterConnect()});
        }
    }, []);
  
    return (
        <div>
            {globalState.installMetamask && <button type="submit" className="btn btn-primary" >Install Metamask</button>}
            {(!globalState.installMetamask && globalState.currentAddress=="") && <button type="submit" className="btn btn-primary" onClick={connect}>Connect</button>}
            {globalState.currentAddress!= "" && 
                <>
                    <span className="btn-primary btn  ml-1 mr-1">Address: {reFormatHexAddress(globalState.currentAddress)}</span>
                    <span className="btn-primary btn ml-1 mr-1"> USDC: {globalState.collateralBalance}</span>
                </>
            }
        </div>
    );
};