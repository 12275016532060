import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Web3 from "web3";
import configData from "../../config.json";
import ConditionalToken from "../../abis/ConditionalTokens.json"
import FixedProductMarketMaker from "../../abis/FixedProductMarketMaker.json"
import FixedProductMarketMakerFactory from "../../abis/FixedProductMarketMakerFactory.json"
import FPMMDeterministicFactory from "../../abis/FPMMDeterministicFactory.json"
import USDC from "../../abis/USDC.json"
import { marketService, UmaCtfAdapterService } from '../../_services/index';
import { amountSToB, amountBToS, getIndexesFromInt, getRealityEthQuestionLink} from '../../_helpers/utilities';
import { toast } from 'react-toastify';
const { getCollectionId, getPositionId } = require('@gnosis.pm/conditional-tokens-contracts/utils/id-helpers')(Web3.utils)
const BN = require('bn.js');



const Market = (props) => {
    const [fetching, setFetching] = useState(false);
    const [liquidityError, setLiquidityError] = useState('');
    const [initialLiquidity, setInitialLiquidity] = useState('');
    const [payoutIndex, setPayoutIndex] = useState('');
    const [payoutsForResolution, setPayoutsForResolution] = useState('');
    const [selectedOptionTextForResolution, setSelectedOptionTextForResolution] = useState('');
    let [loadedContracts, setLoadedContracts] = useState({
        collateralToken : null,
        conditionalToken : null,
        fixedProductMarketMaker: null
    });
    const [marketInfo, setMarketInfo] = useState({
        conditionId: null,
        indexSets: [Web3.utils.toBN(1).shln(0), Web3.utils.toBN(1).shln(1)],
        collectionIds: [],
        positionIds: [],
        fixedProductMarketMaker: null,
        alreadyFunded : false
    })
    const [serverMarketData, setServerMarketData] = useState(null);

    const setMarketDataAfterFetch = async (data) => {
        console.log('market server data ', data)
        setServerMarketData(data);
        setMarketInfo({
            ...marketInfo,
            conditionId: data.condition_id,
            collectionIds: Array.from(data.options, option => option.collection_id),
            positionIds: Array.from(data.options, option => option.position_id),
            fixedProductMarketMaker: data.fpmm_market_maker_address
        })
    }
 
    const loadData = async () => {
        // get market details
        marketService.getMarketDetails(props.match.params.id).then(
            data => {
                setMarketDataAfterFetch(data);
            },
            error => {
                console.log(error)
            }
        )
    }

    const loadContracts = async () => {
        if(props.globalState.currentAddress!=""){
            const collateralToken = await new props.globalState.web3.eth.Contract(USDC, configData.addresses.collateralToken);
            const conditionalToken = await new props.globalState.web3.eth.Contract(ConditionalToken.abi, configData.addresses.conditionalToken);
            const fixedProductMarketMakerFactory = await new props.globalState.web3.eth.Contract(FixedProductMarketMakerFactory.abi, configData.addresses.fixedProductMarketMakerFactory);
            const fPMMDeterministicFactory = await new props.globalState.web3.eth.Contract(FPMMDeterministicFactory.abi, configData.addresses.FPMMDeterministicFactory);
            const contracts =  {
                collateralToken: collateralToken, 
                conditionalToken: conditionalToken,
                fixedProductMarketMakerFactory,
                fPMMDeterministicFactory
            };
            setLoadedContracts(contracts);
            return contracts
        }
    }

    const getCollectionAndPositionIds = (conditionId) => {
        const collectionIds = Array.from(
            { length: 2 },
            (_, i) => getCollectionId(conditionId, Web3.utils.toBN(1).shln(i))
        );

        let positionIds
        positionIds = collectionIds.map(collectionId => {
            let positionId = getPositionId(configData.addresses.collateralToken, collectionId)
            return positionId;
        })
        
        return {
            positionIds, collectionIds
        }
    }
    
    const createMarketUsingDeterministicFactory = async (e) => {
        const contracts = await loadContracts();
        const from = props.globalState.currentAddress;

        let fixedProductMarketMaker;
        const feeFactor = Web3.utils.toBN(configData.FEE_FACTOR)
        const address = configData.addresses.FPMMDeterministicFactory;
        // const amountToBeFunded = amountBToS(configData.INITIAL_FUNDING_AMOUNT);
        const amountToBeFunded = amountBToS(`${serverMarketData.initial_funding_amount}`);
        const saltNonce = Web3.utils.toBN(2020787845454848)
        const createArgs = [
            saltNonce,
            configData.addresses.conditionalToken,
            configData.addresses.collateralToken,
            [marketInfo.conditionId],
            feeFactor,
            amountToBeFunded,
            [serverMarketData.options[0].odd_value,serverMarketData.options[1].odd_value]
        ]

        const approvalNeeded = await doWeNeedToGetAllowance(from, address, amountToBeFunded)

        if(approvalNeeded){
            const receipt = await contracts.collateralToken.methods.approve(address, amountToBeFunded).send({from: from})
            console.log('approval receipt ', receipt)
        }


        // const fixedProductMarketMakerAddress = await fpmmDeterministicFactory.create2FixedProductMarketMaker.call(...createArgs)
        // const createTx = await fpmmDeterministicFactory.create2FixedProductMarketMaker(...createArgs);
        const fixedProductMarketMakerAddress = await contracts.fPMMDeterministicFactory.methods.create2FixedProductMarketMaker(...createArgs).call({from: from});
        console.log('fixedProductMarketMakerAddress address from call ', fixedProductMarketMakerAddress)
        contracts.fPMMDeterministicFactory.methods.create2FixedProductMarketMaker(...createArgs).send({from: from})
        .on('transactionHash', function(hash){
            console.log('transaction hash : ', hash)
        })
        .on('receipt', function(receipt){
            console.log('market creation receipt ', receipt)
            setMarketInfo({...marketInfo, fixedProductMarketMaker:receipt.events.FixedProductMarketMakerCreation.returnValues.fixedProductMarketMaker});
            //update the condition id, collectionIds and positionIds in the database
            marketService.updateMarketAfterMarketCreation(serverMarketData.id, receipt)
            .then(
                response => {
                    setMarketDataAfterFetch(response)
                },
                error => {
                    console.log('error occured ', error)
                }
            );
        })
        .on('confirmation', function(confirmationNumber, receipt){
            console.log('transaction confirmation number: ', confirmationNumber);
            console.log('transaction confirmation receipt:', receipt);
        })
        .on('error', function(error, receipt) {
            console.log('transaction error : ', error);
            console.log('transaction receipt:', receipt);
        });
    }

    const getAllowance = async (owner, spender) => {
        const contracts = await loadContracts();
        const from = props.globalState.currentAddress;
        try{
            const result = await contracts.collateralToken.methods.allowance(owner, spender).call({from: from})
            return parseInt(result);
        }catch(error){
            throw error;
        }
    }

    const doWeNeedToGetAllowance = async (owner, spender, amount) => {
        try{
            const result = await getAllowance(owner, spender);
            return amount <= result ? false : true;
        }catch(error){
            throw error;
        }
    }

    const createConditionSucceeded = async () => {
        try{
            var response = await marketService.updateCreateConditionConfirmed(serverMarketData.id, 1);
            setMarketDataAfterFetch(response)
            toast.success("Condition confirmation reported, proceed to create and link market to condition.")
        }catch{
            toast.error("Reporting failed")
        }
    }

    const createConditionFailed = async () => {
        try{
            var response = await marketService.updateCreateConditionConfirmed(serverMarketData.id, 0);
            setMarketDataAfterFetch(response)
            toast.success("Condition failure reported, create condition again.")
        }catch{
            toast.error("Reporting failed")
        }
    }
    
    const createRealityQuestionSucceeded = async () => {
        try{
            var response = await marketService.updateRealityQuestionConfirmed(serverMarketData.id, 1);
            setMarketDataAfterFetch(response)
            toast.success("Question confirmation reported, proceed to create condition.")
        }catch{
            toast.error("Reporting failed")
        }
    }

    const createRealityQuestionFailed = async () => {
        try{
            var response = await marketService.updateRealityQuestionConfirmed(serverMarketData.id, 0);
            setMarketDataAfterFetch(response)
            toast.success("Question failure reported, create question again.")
        }catch{
            toast.error("Reporting failed")
        }
    }

    const createMarketSucceeded = async () => {
        try{
            var response = await marketService.updateCreateMarketConfirmed(serverMarketData.id, 1);
            setMarketDataAfterFetch(response)
            toast.success("Market confirmation reported, proceed to fund the market for the first time.")
        }catch{
            toast.error("Reporting failed")
        }
    }

    const createMarketFailed = async () => {
        try{
            var response = await marketService.updateCreateMarketConfirmed(serverMarketData.id, 0);
            setMarketDataAfterFetch(response)
            toast.success("Market failure reported, create market again.")
        }catch{
            toast.error("Reporting failed")
        }
    }  

    const resolutionSucceeded = async () => {
        try{
            var response = await marketService.updateResolveMarketConfirmed(serverMarketData.id, 1);
            setMarketDataAfterFetch(response)
            toast.success("Market resolution reported.")
        }catch{
            toast.error("Reporting failed")
        }
    }

    const resolutionFailed = async () => {
        try{
            var response = await marketService.updateResolveMarketConfirmed(serverMarketData.id, 0);
            setMarketDataAfterFetch(response)
            toast.success("Resolution failure reported, resolve market again.")
        }catch{
            toast.error("Reporting failed")
        }
    }  

    // const createCondition = async () => {
    //     const { getConditionId, getCollectionId, getPositionId } = require('@gnosis.pm/conditional-tokens-contracts/utils/id-helpers')(Web3.utils)
    //     const contracts = await loadContracts();
    //     const from = props.globalState.currentAddress;
    //     console.log(Web3.utils.randomHex(32))
    //     const questionId = serverMarketData.question_id;
    //     const numOutcomes = 2;
    //     const oracle = serverMarketData.oracle;
    //     console.log('qid', questionId, 'oracle ', oracle)
    //     // create condition
    //     contracts.conditionalToken.methods.prepareCondition(oracle, questionId, numOutcomes).send({from: props.globalState.currentAddress})
    //     .on('transactionHash', function(hash){
    //         console.log('transaction hash : ', hash)
    //     })
    //     .on('receipt', function(receipt){
    //         const conditionId = receipt.events.ConditionPreparation.returnValues.conditionId;
    //         console.log('transaction receipt : ', receipt)
    //         const collectionAndPositionIds = getCollectionAndPositionIds(conditionId)
    //         setMarketInfo({...marketInfo, conditionId: conditionId, ...collectionAndPositionIds})
    //         marketService.updateMarketAfterConditionCreation(serverMarketData.id, receipt, collectionAndPositionIds.collectionIds, collectionAndPositionIds.positionIds)
    //         .then(
    //             response => {
    //                 setMarketDataAfterFetch(response)
    //                 console.log('res', response)
    //             },
    //             error => {
    //                 console.log('error occured ', error)
    //             }
    //         );
    //     })
    //     .on('confirmation', function(confirmationNumber, receipt){
    //         console.log('transaction confirmation number: ', confirmationNumber);
    //         console.log('transaction confirmation receipt:', receipt);
    //     })
    //     .on('error', function(error, receipt) {
    //         console.log('transaction error : ', error);
    //         console.log('transaction receipt:', receipt);
    //     });
    // }

    const createCondition = async () => {
        
        // marketService.updateMarketAfterConditionCreation(serverMarketData.id, receipt, collectionAndPositionIds.collectionIds, collectionAndPositionIds.positionIds)
        // .then(
        //     response => {
        //         setMarketDataAfterFetch(response)
        //         console.log('res', response)
        //     },
        //     error => {
        //         console.log('error occured ', error)
        //     }
        // );
    }

    const onUmaCtfAdapterTransactionHash = (hash) => {
        console.log('transaction hash : ', hash)
    }
    const onUmaCtfAdapterReceipt = (receipt) => {
        console.log('on reality receipt ',serverMarketData.id, receipt)
        const conditionId = receipt.events[0].raw.topics[1];
        const collectionAndPositionIds = getCollectionAndPositionIds(conditionId)
        setMarketInfo({...marketInfo, conditionId: conditionId, ...collectionAndPositionIds})

        marketService.updateMarketAfterUmaQuestionCreation(serverMarketData.id, receipt, conditionId, collectionAndPositionIds.collectionIds, collectionAndPositionIds.positionIds)
        .then(
            response => {
                setMarketDataAfterFetch(response)
                console.log('res', response)
            },
            error => {
                console.log('error occured ', error)
            }
        );
    }
    const onUmaCtfAdapterConfirmation = (confirmationNumber, receipt) => {
        console.log('transaction confirmation number: ', confirmationNumber);
        console.log('transaction confirmation receipt:', receipt);
    }
    const onUmaCtfAdapterError = (error, receipt) => {
        console.log('transaction error : ', error);
        console.log('transaction receipt:', receipt);
    }

    const createQuestionAndCondition = async () => {
        const from = props.globalState.currentAddress;
        let ancillary_data = serverMarketData.uma_question_ancillary_data;
        let reward_token = serverMarketData.reward_token;
        let reward = serverMarketData.reward;
        let proposal_bond = serverMarketData.proposal_bond;
        let liveness = serverMarketData.liveness;

        const realityResponse = await UmaCtfAdapterService.createQuestionAndCondition(
            {
                props,
                from,
                ancillary_data,
                reward_token,
                reward,
                proposal_bond,
                liveness,
                onTransactionHash : onUmaCtfAdapterTransactionHash,
                onReceipt: onUmaCtfAdapterReceipt,
                onConfirmation: onUmaCtfAdapterConfirmation,
                onError: onUmaCtfAdapterError
            }
        );
    }

    

    const payoutIndexChanged = (e) => {
        const re = /^[0-9\b]+$/;
        var val = e.target.value;
        if (val === '' || (re.test(val) && parseInt(val)>0 && parseInt(val)<3)) {
            setPayoutIndex(val);
            const selectedOptionVal = val=='' ?  '' : serverMarketData.options[val-1].option;
            setSelectedOptionTextForResolution(selectedOptionVal);
            setPayoutsForResolution(getIndexesFromInt(val));
        }else{
            toast.error("Invalid index")
        }
    }

    const reportPayout = async () => {
        if(payoutIndex==''){
            toast.error("Can't resolve with empty index!")
            return 0;
        }
        // console.log(payoutsForResolution)
        const contracts = await loadContracts();
        const from = props.globalState.currentAddress;
        const questionId = serverMarketData.question_id;
        const oracle = serverMarketData.oracle;
        
        // create condition
        contracts.conditionalToken.methods.reportPayouts(questionId, payoutsForResolution).send({from: from})
        .on('transactionHash', function(hash){
            console.log('transaction hash : ', hash)
        })
        .on('receipt', function(receipt){
            console.log('transaction receipt : ', receipt)
            marketService.updateMarketAfterResolution(serverMarketData.id, receipt, payoutsForResolution.join(''))
            .then(
                response => {
                    setMarketDataAfterFetch(response)
                },
                error => {
                    console.log('error occured ', error)
                }
            );
        })
        .on('confirmation', function(confirmationNumber, receipt){
            console.log('transaction confirmation number: ', confirmationNumber);
            console.log('transaction confirmation receipt:', receipt);
        })
        .on('error', function(error, receipt) {
            console.log('transaction error : ', error);
            console.log('transaction receipt:', receipt);
        });
    }

    useEffect(() => {
        loadData()
    }, []);
  
    return (
        <div>
            {serverMarketData!=null && serverMarketData.resolution_confirmed_by_admin==1 &&
                <div className="alert alert-primary" role="alert">
                    Market has been resolved in favor of "{serverMarketData.options.filter(e => e.resolved==1).map(e => e.option)}"
                </div>
            }
            <h2>Market</h2> 
            {serverMarketData!=null && 
                <>
                    <div className="text-capitalize font-weight-bold">{serverMarketData.question}</div>
                    <table className="table table-sm mt-3 mb-1">
                        <thead>
                            <tr>
                                <th scope="col" className="font-weight-0">Option</th>
                                <th scope="col">Odd value</th>
                                <th scope="col">Resolved in favor of it</th>
                            </tr>
                        </thead>
                        <tbody>
                        {serverMarketData.options.map(e => {
                                return <tr key={e.id}>
                                        <td>{e.option}</td>
                                        <td>{e.odd_value}</td>
                                        <td>{e.resolved==1 ? 'Yes' : 'No'}</td>
                                    </tr>;
                            }
                        )}
                        </tbody>
                    </table>
                    {serverMarketData.question_id!=null && <div className="mb-2"><strong>UmaCtfAdapter Question id: </strong>{serverMarketData.question_id}</div>}
                    {/* {serverMarketData.question_id!=null && 
                        <>
                            <strong>RealityEth Question: </strong>

                            <a target="_blank" rel="noopener noreferrer" href={getRealityEthQuestionLink(serverMarketData.question_id)}>
                                Click to view question on realityEth
                            </a>
                        </>
                    } */}
                    {/* <div className="mb-2"><strong>Category: </strong>{serverMarketData.category}</div> */}
                    <div className="mb-2"><strong>Question Ancillary Data: </strong>{serverMarketData.uma_question_ancillary_data}</div>
                    <div className="mb-2"><strong>Permalink: </strong>{serverMarketData.permalink}</div>
                    <div className="mb-2"><strong>Oracle: </strong>{serverMarketData.oracle}</div>
                    <div className="mb-2"><strong>Initial Funding: </strong>${serverMarketData.initial_funding_amount}</div>
                    <div className="mb-2"><strong>Reward Token: </strong>{serverMarketData.reward_token}</div>
                    <div className="mb-2"><strong>Reward: </strong>${serverMarketData.reward}</div>
                    <div className="mb-2"><strong>Proposal Bond: </strong>${serverMarketData.proposal_bond}</div>
                    <div className="mb-2"><strong>Liveness: </strong>{serverMarketData.liveness} secs</div>
                    {/* <div className="mb-2"><strong>Opening date: </strong>{serverMarketData.market_ends_on}</div> */}
                    <div><strong>About Market</strong></div>
                    <div className="mb-2">{serverMarketData.about_market}</div>
                    {serverMarketData.resolution_source!=null && <div className="mb-2"><strong>Resolution Source: </strong>{serverMarketData.resolution_source}</div>}
                    {serverMarketData.condition_id!=null && 
                        <>
                            <div><strong>Condition ID</strong></div>
                            <div className="mb-2">{serverMarketData.condition_id}</div>
                            <div><strong>Condition Tx Hash</strong></div>
                            <div className="mb-2">{serverMarketData.blockchain_condition_response.transactionHash}</div>
                        </>
                    }
                    {serverMarketData.fpmm_market_maker_address!=null && 
                        <>
                            <div><strong>Fpmm Market Maker Address</strong></div>
                            <div className="mb-2">{serverMarketData.fpmm_market_maker_address}</div>
                            <div><strong>Fpmm Market Maker Tx Hash</strong></div>
                            <div className="mb-2">{serverMarketData.blockchain_create_market_response.transactionHash}</div>
                        </>
                    }
                </>
            }
            {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.question_id==null  && <button className="btn btn-primary btn-sm" disabled={fetching} onClick={createQuestionAndCondition}>Create Question and Condition</button>}
            {/* {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.question_id!=null && serverMarketData.reality_question_confirmed_on_blockchain==0 &&
                <>
                    <button className="btn btn-primary btn-sm mr-2" disabled={fetching} onClick={createRealityQuestionSucceeded}>Report Reality question Created on blockchain</button>
                    <button className="btn btn-primary btn-sm" disabled={fetching} onClick={createRealityQuestionFailed}>Report Reality question failed on blockchain</button>
                </>
            } */}
            {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.reality_question_confirmed_on_blockchain==1 && marketInfo.conditionId==null && <button className="btn btn-primary btn-sm" disabled={fetching} onClick={createCondition}>Create Condition</button>}
            {props.globalState.currentAddress && serverMarketData!=null && marketInfo.conditionId!=null && serverMarketData.condition_confirmed_on_blockchain==0 &&
                <>
                    <button className="btn btn-primary btn-sm mr-2" disabled={fetching} onClick={createConditionSucceeded}>Report Condition Created on blockchain</button>
                    <button className="btn btn-primary btn-sm" disabled={fetching} onClick={createConditionFailed}>Report Condition failed on blockchain</button>
                </>
            }

            {props.globalState.currentAddress &&
                serverMarketData!=null &&
                serverMarketData.condition_confirmed_on_blockchain==1 &&
                marketInfo.fixedProductMarketMaker==null &&
                <button 
                    className="btn btn-primary btn-sm" 
                    disabled={fetching} 
                    onClick={(e) => createMarketUsingDeterministicFactory(e)}
                >
                    Create Market
                </button>
            }
            {props.globalState.currentAddress && serverMarketData!=null && marketInfo.fixedProductMarketMaker!=null && serverMarketData.fpmm_confirmed_on_blockchain==0 &&
                <>
                    <button className="btn btn-primary btn-sm mr-2" disabled={fetching} onClick={createMarketSucceeded}>Report Market Created on blockchain</button>
                    <button className="btn btn-primary btn-sm" disabled={fetching} onClick={createMarketFailed}>Report Market failed on blockchain</button>
                </>
            }
            {props.globalState.currentAddress && serverMarketData!=null 
                && serverMarketData.blockchain_resolution_response==null 
                && serverMarketData.initial_liquidity_confirmed==1 
                && serverMarketData.oracle == props.globalState.currentAddress && 
                (<div className="border p-2">
                    <h5>Resolve Market</h5>
                    <div>
                        <label className="d-block font-weight-bold">Payout Index</label>
                        <input type="number" value={payoutIndex} name="payoutIndex" onChange={payoutIndexChanged}/>
                        {selectedOptionTextForResolution && 
                            <>
                                <span className="ml-2">{selectedOptionTextForResolution}:</span>
                                <span className="ml-2">{payoutsForResolution}</span>
                            </>
                        }
                    </div>
                    <div className="mt-2"><button disabled={fetching} onClick={reportPayout} className="btn btn-primary btn-sm">Resolve Market</button></div>
                </div>)
            }
            {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.blockchain_resolution_response!=null && 
                <>
                    <div><strong>Market Resolution Transaction Hash</strong></div>
                    <div className="mb-2">{serverMarketData.blockchain_resolution_response.transactionHash}</div>
                </>
            }
            {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.blockchain_resolution_response!=null && 
                <>
                    <div><strong>Market Resolution Transaction Hash</strong></div>
                    <div className="mb-2">{serverMarketData.blockchain_resolution_response.transactionHash}</div>
                </>
            }
            {props.globalState.currentAddress && serverMarketData!=null && serverMarketData.blockchain_resolution_response!=null && serverMarketData.resolution_confirmed_by_admin==0 && 
                <>
                    <div className="font-weight-bold">Resolution submitted to blockchain. Confirm blockchain transaction result.</div>
                    <button className="btn btn-primary btn-sm mr-2" disabled={fetching} onClick={resolutionSucceeded}>Resolution succeeded on blockchain</button>
                    <button className="btn btn-primary btn-sm" disabled={fetching} onClick={resolutionFailed}>Resolution failed on blockchain</button>
                </>
            }
            
            {liquidityError!='' && (<div>{liquidityError}</div>)}
        </div>
    );
};

export default Market;