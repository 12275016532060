import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { marketService } from '../../_services/index';

export const PublishedMarkets = () => {
    let [markets, setMarkets] = useState([]);
    let [errorToBeShown, setErrorToBeShown] = useState(null);

    useEffect(() => {
        marketService.getPublished().then(
            data => {
                setMarkets(data);
            },
            error => {
                setErrorToBeShown(error);
            }
        );
    }, []);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(markets);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setMarkets(items);

        // Update the order in the database
        items.forEach((item, index) => {
            marketService.updateOrder(item.id, index + 1);
        });
    };

    return (
        <div>
            {errorToBeShown &&
                <div className={'alert alert-danger'}>{errorToBeShown}</div>
            }
            {markets &&
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="markets">
                        {(provided) => (
                            <table className="table" {...provided.droppableProps} ref={provided.innerRef}>
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Question</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {markets.map((element, index) => (
                                        <Draggable key={element.id} draggableId={element.id.toString()} index={index}>
                                            {(provided) => (
                                                <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td><Link to={`market/${element.id}`}>{element.question}</Link></td>
                                                    <td>{new Date(element.created_at).toDateString()}</td>
                                                    <td>
                                                        {element.blockchain_resolution_response == null && <Link to={`market/${element.id}`}>Resolve</Link>}
                                                        {element.blockchain_resolution_response != null && element.resolution_confirmed_by_admin == 0 &&
                                                            <Link to={`market/${element.id}`}>Confirm blockchain transaction</Link>
                                                        }
                                                        {element.resolution_confirmed_by_admin == 1 && `Resolved`}
                                                    </td>
                                                    <td>
                                                        {element.blockchain_resolution_response == null && <Link to={`market/edit/${element.id}`}>Edit</Link>}
                                                    </td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </tbody>
                            </table>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </div>
    );
};
