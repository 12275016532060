import UmaCtfAdapterAbi from "../abis/UmaCtfAdapter.json"
import configData from "../config.json"
import Web3 from "web3";


export const UmaCtfAdapterService = {
    createQuestionAndCondition,
}

async function createQuestionAndCondition({
    props,
    from,
    ancillary_data,
    reward_token,
    reward,
    proposal_bond,
    liveness,
    onTransactionHash,
    onReceipt,
    onConfirmation,
    onError
}){
    try{
        const UmaCtfAdapter = await new props.globalState.web3.eth.Contract(UmaCtfAdapterAbi, configData.addresses.umaCtfAdapter)

        UmaCtfAdapter
        .methods
        .initialize(
            ancillary_data,
            reward_token,
            reward,
            proposal_bond,
            liveness
        )
        .send({
            from
        })
        .on('transactionHash', function(hash){
            onTransactionHash(hash)
        })
        .on('receipt', function(receipt){
            onReceipt(receipt)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            onConfirmation(confirmationNumber, receipt)
        })
        .on('error', function(error, receipt) {
            onError(error, receipt)
        });
    }catch(e){
        console.log(e)
        throw e;
    }
}