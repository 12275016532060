import React, {useEffect, useState, Fragment} from 'react';
import { marketService } from '../../_services/index';
import * as yup from 'yup';
import { toast } from 'react-toastify';


const EditMarket = (props) => {
    const schema = yup.object().shape({
        newsTitle: yup.string().required(),
        newsDescription: yup.string().required(),
        readMoreLink: yup.string().url(),
    });
      
    const [saving, setSaving] = useState(false);
    const [market, setMarket] = useState(null);
    const [aboutMarket, setAboutMarket] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [news, setNews] = useState([
        { newsTitle: '', newsDescription: '', readMoreLink: '' }
    ]);
    const [newsErrors, setNewsErrors] = useState([
        { newsTitle: '', newsDescription: '', readMoreLink: '' }
    ]);

    const setInitialErrors = (newsCount) => {
        if(newsCount>1){
            var newErrorArr = new Array();
            for(var i = 0; i<newsCount; i++){
                newErrorArr.push({ newsTitle: '', newsDescription: '', readMoreLink: '' });
            }
            setNewsErrors(newErrorArr);
        }
    }

    const setMarketDataAfterFetch = async (data) => {
        if(data.news!=null){
            setInitialErrors(data.news.length)
            setNews(data.news)
        }
        setAboutMarket(data.about_market)
        setMarket(data);
    }
 
    const loadData = async () => {
        marketService.getMarketDetails(props.match.params.id).then(
            data => {
                setMarketDataAfterFetch(data);
            },
            error => {
                // console.log(error)
            }
        )
    }

    useEffect(() => {
        loadData()
    }, []);

    const handleAddFields = () => {
        const errorValues = [...newsErrors];
        errorValues.push({ newsTitle: '', newsDescription: '', readMoreLink: '' });
        setNewsErrors(errorValues);
        
        const values = [...news];
        values.push({ newsTitle: '', newsDescription: '', readMoreLink: '' });
        setNews(values);
    };
    
    const handleRemoveFields = index => {
        const values = [...news];
        values.splice(index, 1);
        setNews(values);

        const errorValues = [...newsErrors];
        errorValues.splice(index, 1);
        setNewsErrors(errorValues);
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true)
        let isValid = 1;
        
        if(aboutMarket == ''){
            isValid = 0;
            setDescriptionError('About Market is required.')
        }

        news.map(async (element, inputFieldRowIndex) => {
            try{
                await schema.validateSync(element, { abortEarly: false })
            }catch(catchedErrors){
                isValid = 0;
                const errorValues = [...newsErrors];
                catchedErrors.inner.forEach((e) => {
                    errorValues[inputFieldRowIndex][e.path] = e.message;
                    setNewsErrors(errorValues);
                })
                setSaving(false)
            }
        })

        if(isValid==1){
            try{
                var response = await marketService.updateMarket(market.id, aboutMarket, news);
                toast.success('Saved successfully!')
                setSaving(false);
                
            }catch(e){
                toast.error(e)
                setSaving(false);
            }
        }else{
          setSaving(false);
        }
        
    };

    const handleMarketDescriptionChange = (e) => {
        setAboutMarket(e.target.value);
    }
    
    const handleInputChange = (index, event) => {
        const values = [...news];
        if (event.target.name === "newsTitle") {
            values[index].newsTitle = event.target.value;
        } else if(event.target.name == "newsDescription") {
            values[index].newsDescription = event.target.value;
        } else if(event.target.name == "readMoreLink") {
            values[index].readMoreLink = event.target.value;
        }

        setNews(values);
    };
    
    
    return (
        <>
          <h1>Edit Market</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group col-sm-10">
                    <label htmlFor="marketDescription">About market</label>
                    <textarea
                    className="form-control"
                    id="aboutMarket"
                    name="aboutMarket"
                    value={aboutMarket}
                    onChange={handleMarketDescriptionChange}
                    />
                    {descriptionError!=='' && <small className="form-text text-muted">{descriptionError}</small> }
                </div>
                <h3 className="col-sm-12">News</h3>
              {news.map((inputField, index) => (
                <Fragment key={`${inputField}~${index}`}>
                  <div className="form-group col-sm-10">
                    <label htmlFor="newsTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="newsTitle"
                      name="newsTitle"
                      value={inputField.newsTitle}
                      onChange={event => handleInputChange(index, event)}
                    />
                    {newsErrors[index].newsTitle!=='' && <small className="form-text text-muted">{newsErrors[index].newsTitle}</small> }
                  </div>
                  <div className="form-group col-sm-2">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                    >
                      -
                    </button>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => handleAddFields()}
                    >
                      +
                    </button>
                  </div>
                  <div className="form-group col-sm-10">
                    <label htmlFor="newsDescription">Description</label>
                    <textarea
                      className="form-control"
                      id="newsDescription"
                      name="newsDescription"
                      value={inputField.newsDescription}
                      onChange={event => handleInputChange(index, event)}
                    />
                    {newsErrors[index].newsDescription!=='' && <small className="form-text text-muted">{newsErrors[index].newsDescription}</small> }
                  </div>
                  <div className="form-group col-sm-10">
                    <label htmlFor="readMoreLink">Read More link</label>
                    <input
                      type="text"
                      className="form-control"
                      id="readMoreLink"
                      name="readMoreLink"
                      value={inputField.readMoreLink}
                      onChange={event => handleInputChange(index, event)}
                    />
                    {newsErrors[index].readMoreLink!=='' && <small className="form-text text-muted">{newsErrors[index].readMoreLink}</small> }
                  </div>
                  
                </Fragment>
              ))}
            </div>
            <div className="submit-button">
              <button
                className="btn btn-primary mr-2"
                type="submit"
                onSubmit={handleSubmit}
                disabled={saving}
              >
                Save
              </button>
              {saving && <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>}
            </div>
          </form>
        </>
      )
    
};

export default EditMarket;