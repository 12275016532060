import supportedChains from "./chains";
import Web3 from "web3";
import configData from "../config.json";


//converts small currency unit to bigger unit
export function amountSToB(value){
  const bVal = Web3.utils.toBN(value)
  return Web3.utils.fromWei(bVal, 'mwei')
}

//converts big currency unit to smaller units
export function amountBToS(value){
  return Web3.utils.toWei(value, 'mwei')
}


export function getIndexesFromInt(value){
  var val = parseInt(value);
  const payouts = Array.from({
      length: 2
    },
    (value, index) => (index+1 === val ? 1 : 0),
  )
  return payouts;
}

export function getChainData(chainId){
  const chainData = supportedChains.filter(
    (chain) => chain.chain_id === chainId
  )[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = process.env.REACT_APP_INFURA_ID;

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

    return {
      ...chainData,
      rpc_url: rpcUrl
    };
  }

  return chainData;
}


export function isObject(obj) {
  return typeof obj === "object" && !!Object.keys(obj).length;
}

export function reFormatHexAddress(str){
  var beginningChars = str.substring(0, 6);
  var endingChars = str.substr(str.length - 4);
  return `${beginningChars}....${endingChars}`;
}

export function getRealityEthQuestionLink(questionId){
  console.log(configData)
  return 'https://reality.eth.link/app/#!/question/'+configData.addresses.realityEth+'-'+questionId;
}
