import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Web3 from "web3";
import Web3Modal from "web3modal";
import { toast } from 'react-toastify';
import configData from '../../config';
import { marketService } from '../../_services/index';
import { config } from 'rxjs';


const CreateMarket = (props) => {
    const history = useHistory();
    const [serverErrors, setServerErrors] = useState(null);
    const onQuestionSubmit = async (
        fields, 
        { 
            setStatus, 
            setSubmitting 
        }
    ) => {
        setStatus();
        marketService.createMarket(fields)
        .then(
            response => {
                setSubmitting(false);
                const { from } = { from: { pathname: "/market/"+response.market.id } };
                history.push(from);
            },
            errors => {
                toast.error('Scroll up to see the errors.');
                setServerErrors(Object.values(errors))
                setSubmitting(false);
                setStatus(errors);
            }
        );
    }
    return (
        <div>
            {serverErrors &&
                <div className={'alert alert-danger'}>
                    {serverErrors.map((e, i) => 
                        (<div key={i}>{e}</div>))
                    }
                </div>
            }
            <h2>Create Market</h2>
            <Formik
                initialValues={{
                    question: '',
                }}
                validationSchema={Yup.object().shape({
                    question: Yup.string().required('Question is required'),
                    ancillary_data: Yup.string().required('Question description is required'),
                    reward_token: Yup.string().required('Reward token is required'),
                    reward: Yup.string().required('Reward is required'),
                    proposal_bond: Yup.string().required('Proposal bond is required'),
                    liveness: Yup.string().required('Liveness is required'),
                    option_1: Yup.string().required('Option 1 is required'),
                    option_2: Yup.string().required('Option 2 is required'),
                    category: Yup.string().required('Category is required'),
                    permalink: Yup.string()
                        .matches(/^[0-9A-Za-z-]*$/, 'Only Alpha numeric and - is allowed')
                        .required('Permalink is required'),
                    option_1_odd: Yup.number().required('Option 1 odd is required').positive('Must be positive integer').integer('Must be an integer'),
                    option_2_odd: Yup.number().required('Option 2 odd is required'),
                    // oracle: Yup.string().required('Oracle is required'),
                    initial_funding_amount: Yup.number().required('Initial funding is required').positive('Must be positive integer'),
                    resolution_source: Yup.string().url('Must be a valid url'),
                    image_url: Yup.string().url('Must be a valid url'),
                    market_ends_on: Yup.date('Must be a valid date.').required('Market ends on is required'),
                    about_market: Yup.string().required('About market is required'),
                })}
                onSubmit={async (fields, functions) => await onQuestionSubmit(fields, functions)}
            >
                {({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label className="mb-0" htmlFor="question">Question</label>
                            <Field name="question" type="text" className={'form-control' + (errors.question && touched.question ? ' is-invalid' : '')} />
                            <ErrorMessage name="question" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="ancillary_data">Question Ancillary Data</label>
                            <Field name="ancillary_data" as="textarea" className={'form-control' + (errors.ancillary_data && touched.ancillary_data ? ' is-invalid' : '')}></Field>
                            <ErrorMessage name="ancillary_data" component="span" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="permalink">Permalink</label>
                            <Field name="permalink" type="text" className={'form-control' + (errors.permalink && touched.permalink ? ' is-invalid' : '')} />
                            <ErrorMessage name="permalink" component="div" className="invalid-feedback" />
                            <label className="mb-0" htmlFor="reward_token">Reward token</label>
                            <Field name="reward_token" type="text" className={'form-control' + (errors.reward_token && touched.reward_token ? ' is-invalid' : '')} />
                            <ErrorMessage name="reward_token" component="div" className="invalid-feedback" />
                            <label className="mb-0" htmlFor="reward">Reward</label>
                            <Field name="reward" type="text" className={'form-control' + (errors.reward && touched.reward ? ' is-invalid' : '')} />
                            <ErrorMessage name="reward" component="div" className="invalid-feedback" />
                            <label className="mb-0" htmlFor="proposal_bond">Proposal Bond</label>
                            <Field name="proposal_bond" type="text" className={'form-control' + (errors.proposal_bond && touched.proposal_bond ? ' is-invalid' : '')} />
                            <ErrorMessage name="proposal_bond" component="div" className="invalid-feedback" />
                            <label className="mb-0" htmlFor="liveness">Liveness</label>
                            <Field name="liveness" type="text" className={'form-control' + (errors.liveness && touched.liveness ? ' is-invalid' : '')} />
                            <ErrorMessage name="liveness" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="image_url">Image Url</label>
                            <Field name="image_url" type="text" className={'form-control' + (errors.image_url && touched.image_url ? ' is-invalid' : '')} />
                            <ErrorMessage name="image_url" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="category">Category</label>
                            <Field name="category" type="text" className={'form-control' + (errors.category && touched.category ? ' is-invalid' : '')} />
                            <ErrorMessage name="category" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="option_1">Option 1</label>
                            <Field name="option_1" type="text" className={'form-control' + (errors.option_1 && touched.option_1 ? ' is-invalid' : '')} />
                            <ErrorMessage name="option_1" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="option_2">Option 2</label>
                            <Field name="option_2" type="text" className={'form-control' + (errors.option_2 && touched.option_2 ? ' is-invalid' : '')} />
                            <ErrorMessage name="option_2" component="div" className="invalid-feedback" />
                            <div className="row">
                                <div className="col">
                                    <label className="mb-0 mt-3" htmlFor="option_1_odd">Option 1 Odd</label>
                                    <Field onWheel={() => document.activeElement.blur()} name="option_1_odd" type="number" className={'form-control' + (errors.option_1_odd && touched.option_1_odd ? ' is-invalid' : '')} />
                                    <ErrorMessage name="option_1_odd" component="div" className="invalid-feedback" />
                                </div>
                                <div className="col">
                                    <label className="mb-0 mt-3" htmlFor="option_2_odd">Option 2 Odd</label>
                                    <Field onWheel={() => document.activeElement.blur()} name="option_2_odd" type="number" className={'form-control' + (errors.option_2_odd && touched.option_2_odd ? ' is-invalid' : '')} />
                                    <ErrorMessage name="option_2_odd" component="div" className="invalid-feedback" />
                                </div>
                            </div>
                            {/* <label className="mb-0 mt-3" htmlFor="oracle">Oracle</label> */}
                            {/* <Field name="oracle" type="text" className={'form-control' + (errors.oracle && touched.oracle ? ' is-invalid' : '')} value={configData.addresses.conditionResolverUsingReality} />
                            <ErrorMessage name="oracle" component="div" className="invalid-feedback" /> */}
                            <label className="mb-0 mt-3" htmlFor="oracle">Initial Funding</label>
                            <Field onWheel={() => document.activeElement.blur()} name="initial_funding_amount" type="number" className={'form-control' + (errors.initial_funding_amount && touched.initial_funding_amount ? ' is-invalid' : '')} />
                            <ErrorMessage name="initial_funding_amount" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="market_ends_on">Market Ends On</label><small class="text-muted"></small>
                            <Field name="market_ends_on" format="MM/dd/yyyy" type="date" className={'form-control' + (errors.market_ends_on && touched.market_ends_on ? ' is-invalid' : '')} />
                            <ErrorMessage name="market_ends_on" component="div" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="resolution_source">Market resolution source</label>
                            <Field name="resolution_source" type="text" className={'form-control'} />
                            <ErrorMessage name="resolution_source" component="span" className="invalid-feedback" />
                            <label className="mb-0 mt-3" htmlFor="about_market">About market</label>
                            <Field name="about_market" as="textarea" className={'form-control' + (errors.about_market && touched.about_market ? ' is-invalid' : '')}></Field>
                            <ErrorMessage name="about_market" component="span" className="invalid-feedback" />
                        </div>
                        {props.globalState.currentAddress &&
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Create Question</button>
                                {isSubmitting &&
                                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        }

                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateMarket;