import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';

import { marketService } from '../../_services/index';

export let UnpublishedMarkets = ({globalState, setGlobalState}) => {
    let [markets, setMarkets] = useState(null)
    let [errorToBeShown, setErrorToBeShown] = useState(null)

    useEffect((errorToBeShown) => {
        marketService.getUnpublished().then(
            data => { 
                console.log(data)
                setMarkets(data); 
            },
            error => {
                console.log('error', error)
                setErrorToBeShown(error);
            }
        );
                
    }, [])

    // const test = () => { setGlobalState({...globalState, test:"abc"})  }

    return (
        <div>
            {errorToBeShown && 
                <div className={'alert alert-danger'}>{errorToBeShown}</div>
            }
            {markets &&
                <table className="table">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Question</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {markets.map((element, i) => (
                            <tr key={"question"+i}>
                                <th scope="row">{i+1}</th>
                                <td><Link to={`market/${element.id}`}>{element.question}</Link></td>
                                <td>{new Date(element.created_at).toDateString()}</td>
                                <td>
                                    {element.blockchain_resolution_response==null && <Link to={`market/edit/${element.id}`}>Edit</Link>}
                                </td>
                            </tr>
                        ))} 
                    </tbody>
                </table>
            }
        </div>
    );
};