import { authenticationService } from '../_services/index';

// export function handleResponse(response) {
//     return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//             if ([401, 403].indexOf(response.status) !== -1) {
//                 // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//                 authenticationService.logout();
//                 location.reload(true);
//             }

//             const error = (data && data.message) || response.statusText;
//             return Promise.reject(error);
//         }

//         return data;
//     });
// }

export function handleResponse(response) {
    return response.text().then(text => {
        
        if (!response.ok) {
            const data = text;
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            console.log(data)
            console.log(data.message)
            return Promise.reject(JSON.parse(data));
        }else{
            const data = text && JSON.parse(text);
            return data;
        }
    });
}