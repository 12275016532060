import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../_services/index';

export const PublicRoute = ({component: Component, restricted, ...rest}) => {
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    return (
        <Route {...rest} render={props => {
            const currentUser = authenticationService.currentUserValue;
            if (currentUser && restricted) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
    
            // authorised so return component
            return <Component {...props} />
        }} />
    );
};